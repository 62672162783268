import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OnBanquetGroupAddedEvent, OnBanquetGroupEndedEvent } from '../../models/tools/events.model';
import { PropertyService } from '../property.service';
import { Vendor } from '../vendor.service';
import { HeaderService } from '../header.service';
import { NotificationService } from '../notification.service';
import { Banquet } from '../../models/banquet/banquet';
import { BanquetBuilder } from '../../models/banquet/banquet-builder';
import { forkJoin, Observable } from 'rxjs';
import { BanquetFilter } from '../../models/banquet/banquet-filter';
import { BanquetZoneService } from '../../services/banquet/banquet-zone.service';
import { BaseService } from '../base.service';
import { BanquetComment } from 'src/app/models/banquet/banquet-comment';
import { NotificationAlertType } from 'src/app/models/tools/notification.model';
import { BanquetTable } from 'src/app/models/banquet/banquet-table';
import { ModalData } from 'src/app/models/tools/fields/modal-data.model';
import { ModalAddingService } from './modal/modal-adding.service';


@Injectable({
  providedIn: 'root'
})
export class BanquetsService extends BaseService {


  public ActiveBanquet: Banquet;
  public IsFilterAfterUpload: boolean = false;

  public OnLoadBanquetEvents: Array<any> = new Array<any>();

  public FilterData: BanquetFilter = new BanquetFilter;

  public Builder: BanquetBuilder = new BanquetBuilder([]);

  public IsFirstLoaded: boolean = false;


  constructor(private http: HttpClient, private property: PropertyService,
    public NotificationService: NotificationService, public HeaderService: HeaderService,
    private router: Router, public BanquetZoneService: BanquetZoneService,
    private ModalAddingService: ModalAddingService) {
    super();

    //this.LoadBanquetType();
  }




  public AddBanquet(firstname, lastname, date, phone, status = 1, customNumber = null) {

    console.log("AddBanquet <---- Test");
    var request = "";
    request = `https://ochagzp.com.ua/index.php?route=api/banquet/addBanquet&token=${this.property.Token}`;

    let banquetData = {
      data: {
        name: `Банкет — ${firstname} ${lastname}`,
        category_name: "",
        category_id: "1",
        price: "0",
        guests: "0",
        date: date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0],
        target: "da",
        target_second: "da",
        contact_firstname: firstname,
        contact_lastname: lastname,
        contact_email: "NotADrugAddict420@gmail.com",
        contact_phone: phone,
        children: "0",
        custom_number: customNumber,
        banquet_direction_type_id: "2",
        status: status
      }
    }

    this.http.post(request, banquetData).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {


          this.GetBanquet(data["body"]["data"]["objects"]["banquetId"]).subscribe(
            (dataOrder) => {
              let buildingOrder: Banquet = this.Builder.Build(dataOrder["body"]["data"]["objects"]["data"]);
              this.Objects.PushToStart(buildingOrder);
              this.VisibleObjects.PushToStart(buildingOrder);
              this.router.navigate([`/banquet/${buildingOrder.Id}`]);
            }
          );
        }
      });
  }




  public SaveBanquet(banquet: Banquet, onLoadEvent: any = () => { }) {

    let tableIds: Array<number> = new Array<number>();
    banquet.Tables.forEach((table: BanquetTable) => {
      tableIds.push(table.Id);
    });


    let request = `https://ochagzp.com.ua/index.php?route=api/banquet/editBanquet&token=${this.property.Token}&id=${banquet.Id}`;


    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = banquet.Date.getFullYear() + '-' +
      pad(banquet.Date.getMonth() + 1) + '-' +
      pad(banquet.Date.getDate());

    let entity = {
      data: {
        name: banquet.Name,
        category_name: banquet.CategoryName,
        category_id: "1",
        price: 0,
        guests: banquet.Guests,
        status: banquet.Status,
        date: dateSqlStart,
        hours: banquet.DateHour,
        minutes: banquet.DateMinutes,
        target: banquet.Target,
        target_second: banquet.TargetSecond,
        contact_firstname: banquet.ContactCustomer.FirstName,
        contact_lastname: banquet.ContactCustomer.LastName,
        contact_email: banquet.ContactCustomer.Email,
        contact_phone: banquet.ContactCustomer.Phone,
        children: banquet.Children,
        banquet_direction_type_id: banquet.DirectionTypeId,
        tables: tableIds,
        comment: banquet.Comment,
        custom_number: banquet.CustomNumber,
        slider_kitchen_sended: banquet.SliderKitchenSended == true ? "1" : "0",
        kepper_sended: banquet.KepperSended == true ? "1" : "0",
        alcohol: banquet.Alcohol ? "1" : "0",
      }
    };

    this.http.post(request, entity).subscribe(
      (data) => {
        if (data["status"] == "200") {
          //this.NotificationService.Add(`Банкет сохранён`, `Банкет №${banquet.Id} сохранён`, new Date(), NotificationAlertType.Default);
        }

        onLoadEvent(data);
      }
    );



  }

  public ChangeDateBanquet(banquet: Banquet) {
    let request = `https://ochagzp.com.ua/index.php?route=api/banquet/changeDateBanquet&token=${this.property.Token}&id=${banquet.Id}`;
    this.http.get(request).subscribe(
      () => {
        banquet.Payments.forEach(payment => {
          payment.ConfirmedTransfer = false;
        });
      }
    );
  }

  public LoadBanquets(OnLoadedEvents: Array<any> = []) {
    this.IsLoading = true;
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/loadMoreBanquet&token=${this.property.Token}&page=${this.Page}`;

    let dataFilter = {
      filter: this.FilterData.GetFilter()
    }

    this.http.post(request, dataFilter).subscribe(
      (data: any) => {
        if (data["error"] != null) {

        } else {

          data["body"]["data"]["objects"]["data"].forEach(element => {
            let buildingBanquet: Banquet = this.Builder.Build(element);
            this.Objects.PushToEnd(buildingBanquet);
          });

          data["body"]["data"]["objects"]["originals"].forEach(element => {
            this.ObjectsOriginal.PushToEnd(this.Builder.Build(element));
          });

          this.IsLoaded = true;

          this.IsLoading = false;
          this.Page++;
          this.LoadMoreBanquets();
          if (this.IsFirstLoaded == false) {
            this.OnLoadBanquetEvents.forEach(event => {
              event();
            });
          }


          this.IsFirstLoaded = true;

          OnLoadedEvents.forEach((event) => {
            event();
          });
        }
      }
    );
  }

  public GetBanquet(id): Observable<any> {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/newTestGetBanquetById&token=${this.property.Token}&id=${id}`;
    console.log(request);
    return this.http.get(request);
  }

  public LoadMoreBanquets() {
    if (this.IsLoading) {
      return;
    }

    if (this.Page === this.PageOfVisible / 10) {
      this.LoadBanquets();
      return;
    }

    for (let index = this.PageOfVisible * 10; index < this.PageOfVisible * 10 + 10; index++) {
      if (this.Objects.Values[index] != null) {
        this.VisibleObjects.PushToEnd(this.Objects.Values[index]);
      }
    }

    this.PageOfVisible++;
  }

  public ClearBqneuts() {
    this.Page = 0;
    this.PageOfVisible = 0;

    this.Objects = new Vendor();
    this.ObjectsOriginal = new Vendor();
    this.VisibleObjects = new Vendor();
  }

  public OpenBanquet(banquet: Banquet) {
    this.ActiveBanquet = banquet;
    this.router.navigate([`banquet/${banquet.Id}`]);
  }

  public OpenBanquetById(id: number) {
    this.ActiveBanquet = this.Objects.Values.find((object: Banquet) => {
      return object.Id == id;
    }) as Banquet;

    console.log(this.ActiveBanquet);

    if (this.ActiveBanquet != null) {
      if (this.ActiveBanquet != null) {
        this.BanquetZoneService.SetSelectedArea(this.ActiveBanquet.Area);
      }
      if (this.ActiveBanquet != null) {
        this.BanquetZoneService.SetSelectedTables(this.ActiveBanquet.Tables);
      }
      this.router.navigate([`banquet/${id}`]);
    } else {

      let request = this.GetBanquet(id);
      request.subscribe(
        (dataLoadedbanquet) => {
          console.log(dataLoadedbanquet);
          let buildingOrder: Banquet = this.Builder.Build(dataLoadedbanquet["data"]);

          this.Objects.PushToStart(buildingOrder);
          this.VisibleObjects.PushToStart(buildingOrder);
          this.ActiveBanquet = buildingOrder;
          this.router.navigate([`/banquet/${buildingOrder.Id}`]);


        }
      );
    }


  }


  public AddComment(text: any, comment_type_id: any) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-comment/addComment&token=${this.property.Token}`;
    let commentData =
    {
      data: {
        "comment_type_id": comment_type_id,
        "text": text,
        "banquet_id": this.ActiveBanquet.Id,
      }
    }
    this.http.post(request, commentData).subscribe(
      (data: any) => {
        let banquetComment = data["body"]["data"]["objects"]["data"];
        let comment = new BanquetComment(banquetComment["comment_id"]);
        comment.CommentTypeId = banquetComment["comment_type_id"];
        comment.Type = banquetComment["comment"]["comment_type"]["type"];
        comment.Text = banquetComment["comment"]["text"];
        comment.DateAdded = new Date(banquetComment["date_added"].replace(/-/g, "/"));
        // comment.Type = banquetComment["banquet_id"];
        this.ActiveBanquet.Comments.unshift(comment);
      }
    );

  }

  public EditComment(comment: BanquetComment) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/comment/edit&token=${this.property.Token}&id=${comment.Id}`;
    let commentData =
    {
      data: {
        "text": comment.Text,
        "comment_type_id": comment.CommentTypeId,
      }
    }
    this.http.post(request, commentData).subscribe(
      (data: any) => {

      }
    );
  }

  public DeleteComment(id: number) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet-comment/delete&id=${id}&token=${this.property.Token}`;

    this.http.get(request).subscribe(
      (data) => {
        this.ActiveBanquet.Comments = this.ActiveBanquet.Comments.filter(obj => obj.Id !== id);
      }
    );
  }

  public GetBanquetById(id: number): Banquet {
    return this.Objects.Values.find((payment) => {
      return payment.Id == id;
    }) as Banquet;
  }






  public BanquetTypes: Array<any> = new Array<any>();
  public LoadBanquetType(onLoadEvent: any = () => { }) {
    this.BanquetTypes = new Array<any>();
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/loadBanquetTypes&token=${this.property.Token}`;


    let banquetTypeRequest = this.http.get(request);
    forkJoin(banquetTypeRequest);

    banquetTypeRequest.subscribe(
      (data: any) => {
        data["body"]["data"]["objects"]["data"].forEach(element => {
          this.BanquetTypes.push(
            { content: element.name, id: element.category_id },
          );
        });
      }
    ).add(onLoadEvent);

  }


  public ProgramDates: Array<any> = Array<any>();

  public LoadProgramDays(onLoadEvent: any = () => { }) {
    var request = `https://ochagzp.com.ua/index.php?route=api/banquet/loadBanquetProgramDays&token=${this.property.Token}`;

    let banquetTypeRequest = this.http.get(request);




    banquetTypeRequest.subscribe(
      (data: any) => {

        data["body"]["data"]["objects"]["data"].forEach(element => {
          this.ProgramDates.push(
            {
              fromMonth: element.fromMonth,
              doMonth: element.doMonth,
              fromDay: element.fromDay,
              doDay: element.doDay,
              programSum: element.programSum
            },
          );
        });

        this.Builder = new BanquetBuilder(this.ProgramDates);
      }
    ).add(onLoadEvent);
  }





  private BanquetKeeperStatus: Banquet;
  private BanquetKitchenStatus: Banquet;

  OpenModalConfirmKeeperStatus(banquet: Banquet) {
    this.BanquetKeeperStatus = banquet;
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      var request = `https://ochagzp.com.ua/index.php?route=api/banquet/confirmKeeperStatus&token=${this.property.Token}&id=${this.BanquetKeeperStatus.Id}`;

      this.http.get(request).subscribe(
        (data: any) => {
          if (data["status"] == 201) {
            this.BanquetKeeperStatus.KepperSended = true;
          }
        },
      );
    });

    let Modal = new ModalData("Активировать банкет в кипере", "", "Активировать", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(Modal, []);
  }

  OpenModalUnconfirmKeeperStatus(banquet) {
    this.BanquetKeeperStatus = banquet;
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      var request = `https://ochagzp.com.ua/index.php?route=api/banquet/disconfirmKeeperStatus&token=${this.property.Token}&id=${this.BanquetKeeperStatus.Id}`;
      this.http.get(request).subscribe(
        (data: any) => {
          if (data["status"] == 201) {
            this.BanquetKeeperStatus.KepperSended = false;
          }
        },
      );
    });

    let Modal = new ModalData("Выключить банкет в кипере", "", "Выключить", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(Modal, []);
  }






  OpenModalConfirmKitchenStatus(banquet: Banquet) {
    this.BanquetKitchenStatus = banquet;
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      var request = `https://ochagzp.com.ua/index.php?route=api/banquet/confirmSliderKitchen&token=${this.property.Token}&id=${this.BanquetKitchenStatus.Id}`;

      this.http.get(request).subscribe(
        (data: any) => {
          if (data["status"] == 201) {
            this.BanquetKitchenStatus.SliderKitchenSended = true;
          }
        },
      );
    });

    let Modal = new ModalData("Активировать банкет в кипере", "", "Активировать", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(Modal, []);
  }

  OpenModalUnconfirmKitchenStatus(banquet) {
    this.BanquetKitchenStatus = banquet;
    this.ModalAddingService.Reset();
    this.ModalAddingService.AddConfirmEvent((fields: Array<any>) => {
      var request = `https://ochagzp.com.ua/index.php?route=api/banquet/disconfirmSliderKitchen&token=${this.property.Token}&id=${this.BanquetKitchenStatus.Id}`;
      this.http.get(request).subscribe(
        (data: any) => {
          if (data["status"] == 201) {
            this.BanquetKitchenStatus.SliderKitchenSended = false;
          }
        },
      );
    });

    let Modal = new ModalData("Выключить банкет в кипере", "", "Выключить", "Отмена");
    this.ModalAddingService.IsRequired = true;
    this.ModalAddingService.Show(Modal, []);
  }







  ReservedGroupTables: Array<any> = Array<any>();
  ReservedTables: Array<any> = Array<any>();

  public LoadReservedTables(areaId: number, date: Date, date_end: Date, banquetId: number = -1, BanquetTimeId: number = -1) {

    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';

    let request = `https://ochagzp.com.ua/index.php?route=api/banquet/getReservedTables&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&time_id=${BanquetTimeId}`;
    if (banquetId != -1 && banquetId != -10) {
      request += `&banquet_id=${banquetId}`;
    }

    this.http.get(request).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);

      }
    );
  }

  ReserveAllTables(areaId: number, date: Date, date_end: Date, banquet: Banquet, timeId: number = 1) {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';



    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/ReserveAllTable&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&banquet_id=${banquet.Id}&time_id=${timeId}`).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);

      });
  }


  public AddSelectReserveTableGroup(areaId: number, date: Date, date_end: Date, banquet: Banquet, tableId: number, timeId: number = 1) {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';



    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/addSelectReserveTableGroup&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&table_group_id=${tableId}&banquet_id=${banquet.Id}&time_id=${timeId}`).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);

        data["body"]["data"]["objects"]["data"]["addedTables"].forEach(table => {
          banquet.AddTable(new BanquetTable(table["banquet_table_id"], table["name"]));
        });
      });
  }


  public RemoveSelectReserveTableGroup(areaId: number, date: Date, date_end: Date, banquetId: number, tableId: number, timeId: number = 1) {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';


    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/removeSelectReserveTableGroup&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&table_group_id=${tableId}&banquet_id=${banquetId}&time_id=${timeId}`).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);
      });
  }



  public AddSelectReserveTable(areaId: number, date: Date, date_end: Date, banquetId: number, tableId: number, timeId: number = 1) {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';

    let request = `https://ochagzp.com.ua/index.php?route=api/banquet/addSelectReserveTable&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&table_id=${tableId}&banquet_id=${banquetId}&time_id=${timeId}`;

    this.http.get(request).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);
      });
  }


  public RemoveSelectReserveTable(areaId: number, date: Date, date_end: Date, banquetId: number, tableId: number, timeId: number = 1) {
    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';


    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/removeSelectReserveTable&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&table_id=${tableId}&banquet_id=${banquetId}&time_id=${timeId}`).subscribe(
      (data: any) => {
        this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);
      });
  }

  IsAllReservReady: boolean = false;
  IsReserveOtherUser: boolean = false;

  private UpdateReserveTables(tableGroups, tables) {
    this.ReservedTables = new Array<any>();
    this.ReservedGroupTables = new Array<any>();
    this.IsAllReservReady = true;
    this.IsReserveOtherUser = false;

    tables.forEach(element => {
      if (element.status != 'free') {
        this.IsAllReservReady = false;
      }
      if (element.status != 'free' && element.status != 'selected') {
        this.IsReserveOtherUser = true;
      }
      this.ReservedTables.push({
        id: element.id,
        name: element.name,
        status: element.status,
        is_showed: element.is_showed,
        selected: false,
        guests: element.guests,
        children: element.children,
        event_id: element.event_id,
      });
    });

    tableGroups.forEach(element => {
      this.ReservedGroupTables.push({
        id: element.id,
        name: element.name,
        status: element.status,
        selected: false,
      });
    });


  }


  RemoveAllTablesFromBanquet(areaId: number, date: Date, date_end: Date, banquet: Banquet, timeId: number = 1, isUpdate: boolean = true) {

    var pad = function (num) { return ('00' + num).slice(-2) };
    var dateSqlStart;
    dateSqlStart = date.getFullYear() + '-' +
      pad(date.getMonth() + 1) + '-' +
      pad(date.getDate()) + ' ' +
      '00:00:00';

    var dateSqlEnd;
    dateSqlEnd = date_end.getFullYear() + '-' +
      pad(date_end.getMonth() + 1) + '-' +
      pad(date_end.getDate()) + ' ' +
      '00:00:00';

    this.http.get(`https://ochagzp.com.ua/index.php?route=api/banquet/removeAllReserveTable&token=${this.property.Token}&date_start=${dateSqlStart}&area_id=${areaId}&date_end=${dateSqlEnd}&banquet_id=${banquet.Id}&time_id=${timeId}`).subscribe(
      (data: any) => {
        if (isUpdate) {
          this.UpdateReserveTables(data["body"]["data"]["objects"]["data"]["tableGroups"], data["body"]["data"]["objects"]["data"]["tables"]);

        }
      });
  }




}
